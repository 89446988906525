<script>
import * as R from 'ramda';
import axios from 'axios';
import Vue from 'vue';
import moment from 'moment-timezone';
import {
  cleanupClientFields,
  createInstitutionDataForSave,
  mapClientDataForSave,
  mapInjuryAreas,
  mapInstitutionIntoClient,
} from '@/views/Client/Edit/mapper';
import FloatingButtons from '@/components/FloatingButtons.vue';
import TabStatus from '@/views/Client/Edit/Tabs/Status.vue';
import TabGeneral from '@/views/Client/Edit/Tabs/General.vue';
import TabEnrollment from '@/views/Client/Edit/Tabs/Enrollment.vue';
import TabLocalization from '@/views/Client/Edit/Tabs/Localization.vue';
import TabLegal from '@/views/Client/Edit/Tabs/Legal.vue';
import TabMarketing from '@/views/Client/Edit/Tabs/Marketing.vue';
import TabDPT from '@/views/Client/Edit/Tabs/DPT.vue';
import TabDPTGo from '@/views/Client/Edit/Tabs/DPTGo.vue';
import TabBloom from '@/views/Client/Edit/Tabs/Bloom.vue';
import TabMove from '@/views/Client/Edit/Tabs/Move.vue';
import TabMind from '@/views/Client/Edit/Tabs/Mind.vue';
import TabNoProgram from '@/views/Client/Edit/Tabs/NoProgram.vue';
import TabExtraConfiguration from '@/views/Client/Edit/Tabs/ExtraConfiguration.vue';
import RevisionRequest from '@/components/RevisionRequests/index.vue';
import TabEcosystemVendors from '@/views/Client/Edit/Tabs/EcosystemVendors.vue';
import appConfigs from '@/config';
import { VIDEO_CALL_PROVIDERS, ENROLLMENT_CHAT_PROVIDERS } from '@/scripts/constants';

export default {
  name: 'ClientEdit',
  components: {
    FloatingButtons,
    TabExtraConfiguration,
    TabMove,
    TabMind,
    TabBloom,
    TabDPT,
    TabDPTGo,
    TabMarketing,
    TabLegal,
    TabLocalization,
    TabEnrollment,
    TabGeneral,
    TabNoProgram,
    TabStatus,
    RevisionRequest,
    TabEcosystemVendors,
  },
  data() {
    return {
      currentTab: 0,
      liveData: {},
      isRevisionRequestsRequired: false,
      showRevisionRequests: false,
      revisionHiddenFields: [
        'configuration.dpt.conditions',
        'configuration.bloom.conditions',
        'configuration.dpt_go.conditions',
        'configuration.conditions',
        'configuration.dpt.onboarding_forms.synced_at',
        'configuration.toggles.required_member_id_field',
        'onboarding_config_forms',
        'onboarding_config_forms_synced_at',
        'onboarding_config_forms_updated_at',
        'updated_at',
        'logo.selected',
        'logo.available_logos',
      ],
      editing: false,
      processStatus: {
        client: {
          loading: false,
          saving: false,
          fetchError: false,
        },
        clients: {
          loading: false,
          fetchError: false,
        },
        institution: {
          loading: false,
          saving: false,
          fetchError: false,
        },
        injuryAreas: {
          loading: false,
          fetchError: false,
        },
      },
      tabs: {
        general: { isValid: false },
        design: { isValid: false },
        dpt: { isValid: false },
        legal: { isValid: false },
        localization: { isValid: false },
        bloom: { isValid: false },
        marketing: { isValid: false },
        dpt_go: { isValid: false },
        move: { isValid: false },
        mind: { isValid: true },
        noProgram: { isValid: false },
        ecosystemVendors: { isValid: true },
      },
      activeTab: 'TabStatus',
      client_id: 0,
      injuryAreaList: [],
      credentialGroupsList: [],
      clientList: [],
      client: {
        id: null,
        name: null,
        environment: null,
        display_name: null,
        data_access: null,
        powered_by: null,
        logo: {
          /** @type {File[]} Images selected to be uploaded */
          selected: [],
          /** @type {Number|null} ID of image currently selected on dropdown */
          current: null,
          /** @type {{id: Number, display_name: String}[]} All previously uploaded logos */
          available_logos: [],
        },
        reference: null,
        rvp_name: null,
        rvp_email: null,
        institution_id: null,
        parent_id: null,
        sword_parent_id: null, // @DEPRECATED: DO NOT USE
        slugs: [],
        onboarding_flow_uuid: null,
        configuration: {
          client_mode: 'NORMAL',
          capacity: 0,
          support_phone_number: '',
          available_languages: [ '' ],
          country: '',
          data_level_access: 'NORMAL',
          date_format: 'mm-dd-yyyy',
          exclusion_criteria: {},
          language: 'en',
          member_id: null,
          minimum_scheduling_distance_hours: 0,
          custom_client_title: false,
          custom_navigator_titles: false,
          navigator_hide_non_eligible_units: false,
          industry_broad: null,
          industry_sub: null,
          institution_type: null,
          institution_category: null,
          follows_care_ecosystem_rules: 0,
          general_guidelines: null,
          preferred_vendor: null,

          client_email: null,
          client_web_address: null,
          client_phone: null,
          client_address: null,
          client_tier: null,

          normalSupportEmail: '',
          strictSupportEmail: '',
          normalBloomSupportEmail: '',
          strictBloomSupportEmail: '',
          normalSupportPhone: '',
          strictSupportPhone: '',

          onboarding_version: '3.0',
          video_call_provider: VIDEO_CALL_PROVIDERS.ZOOM,
          enrollment_chat_provider: ENROLLMENT_CHAT_PROVIDERS.ZENDESK,
          enrollment_chat_show_percentage: 100,
          service_types: [],
          support_email: '',
          therapy_type: 'intake_flow',
          time_format: '12',
          timezone: 'us',
          institution_timezone: null,
          salesforce_account_id: '',
          salesforce_ultimate_parent_account_id: '',
          member_app_signup_experience: 'NATIVE',
          member_app_enrollment_experience: 'NATIVE',
          navigator_mode: 'default',
          system_mode: 'multi_member',
          enrollment_referrals: false,
          d2c: '',

          marketing: {
            co_branded_logo: '',
          },
          legal_links: {
            en: {
              irb_consent: null,
              hipaa_consent: null,
              non_discrimination: null,
              telehealth_consent: null,
              terms_and_conditions: null,
              disclaimer: null,
            },
            'en-GB': {
              irb_consent: null,
              hipaa_consent: null,
              non_discrimination: null,
              telehealth_consent: null,
              terms_and_conditions: null,
              disclaimer: null,
            },
            es: {
              irb_consent: null,
              hipaa_consent: null,
              non_discrimination: null,
              telehealth_consent: null,
              terms_and_conditions: null,
              disclaimer: null,
            },
            'fr-CA': {
              irb_consent: null,
              hipaa_consent: null,
              non_discrimination: null,
              telehealth_consent: null,
              terms_and_conditions: null,
              disclaimer: null,
            },
            pt: {
              irb_consent: null,
              hipaa_consent: null,
              non_discrimination: null,
              telehealth_consent: null,
              terms_and_conditions: null,
              disclaimer: null,
            },
          },
          toggles: {
            global_client: false,
            allow_preventive_enrollment: false,
            bypass_clinical_referral_rules: false,
            bypass_pt_regulation_status: false,
            can_skip_video_call: false,
            can_upgrade_program: true,
            care_coordinator_enabled: false,
            conservative_mode: false,
            can_create_order_client_test: false,
            enable_health_integration_exchange: false,
            enable_sendbird_video_call: false,
            kit_shipped_only_after_video_call: false,
            preventive_chat_enabled: false,
            require_subscriber_dependent_information: false,
            show_relationship_field_on_onboarding: false,
            required_member_id_field: false,
            send_drop_off_emails: true,
            show_fake_slots: false,
            show_how_long_experiencing_pain_field: true,
            show_member_id_field: false,
            show_on_marketing_pages: false,
            show_payers_list: false,
            show_wizard: false,
            always_show_navigator: false,
            hide_client_logo: false,
            hide_sword_logo: false,
            hide_payer_insurance_fields: false,
            show_get_started_pages: false,
            show_get_started_pages_for_sso: true,
            show_hub_tab: true,
            swag_store_enabled: false,
            dynamic_flow: true,
            show_disclaimer: false,
          },
          video_call: {
            buffer: 0,
            duration: 40,
            pt_provider_duration: 0,
          },
          dpt: {
            capacity: 0,
            show_hub_tab: true,
            allow_phone_call: false,
            call_type: 'intake_flow',
            conditions: [
              'wrist_hand',
            ],
            enabled: false,
            exclusion_criteria: {},
            minimum_scheduling_distance_hours: 1,
            navigator_visible: false,
            number_of_days_to_schedule: 13,
            onboarding_forms: {
              injury_areas: {},
            },
            service_types: [
              'wellness',
            ],
            therapy_type: 'intake_flow',
            on_call_chat: true,
            on_call_technical_launch_date: null,
            academy_enabled: true,
            cbt_program: false,
            credentialRequired: false,
            credential_group_id: null,
            show_on_navigator: true,
            wheel_balance: 100,
            openloop_balance: 0,
            provider_minors_referral: 'OPENLOOP',
            support_email: '',
            toggles: {
              allow_minor_enrollments: true,
              bypass_clinical_referral_rules: false,
              bypass_pt_regulation_status: false,
              can_skip_video_call: false,
              care_coordinator_enabled: false,
              conservative_mode: false,
              enable_sendbird_video_call: false,
              kit_shipped_only_after_video_call: false,
              show_fake_slots: false,
              show_how_long_experiencing_pain_field: true,
              enable_optical_therapies: false,
              is_loki_enabled: true,
              is_loki_phase_two_enabled: false,
              show_referrals: false,
              finish_members_program: false,
            },
            loki: {
              phase_one_enrollment_percentage: 0,
              phase_two_enrollment_percentage: 0,
            },
            finish_members_program: {
              scheduled_start_date: null,
            },
            video_call: {
              buffer: 5,
              duration: 15,
              pt_provider_duration: 25,
            },
            whitelist: false,
            whitelist_fields: [],
            conditionsMapping: [],
            institution_configs: {
              automatic_reassessments: true,
              email_performances: true,
              email_professional_after_session: true,
              email_suggestions: false,
              send_sms_reminders: true,
              sword_go_enabled: false,
              disable_prescription_justification: false,
              reassessments_via: 'email',
              automatic_form_mapping: 'general',
            },
            technical_launch_date: null,
            marketing_launch_date: null,
            unique_addressable_lives: null,
            high_risk: {
              enabled: false,
              allow_phone_call: false,
              can_skip_video_call: false,
              video_call_duration: 15,
              salesforce_account_id: '',
              marketing_launch_date: null,
            },
            address_before_condition: false,
            address_before_condition_percentage: 0,
            onboarding_type: 'enrollment_finish',
            kit_type: 'sensors_and_computer_vision',
            marketing_preferences: {
              allow_free_language: false,
              date_of_first_communication: '',
              member_reference_name: '',
              requires_content_changes_approval: false,
              requires_aesthetic_changes_approval: false,
              eligibility_statement_toggle: false,
              eligibility_statement: '',
              marketing_access: '',
              channel_opt_in: [],
              disclaimer_toggle: false,
              disclaimer: '',
              allow_thematic_campaigns: false,
              sms_allowed: false,
              emails_allowed: false,
            },
            eligibility_criteria: null,
            implementation_status: 'in_implementation',
            benefits_enrolled_eligible: null,
            member_payment: null,
          },
          bloom: {
            redirect_url: null,
            force_eco_experience: true,
            capacity: 0,
            show_hub_tab: true,
            allow_phone_call: false,
            call_type: 'intake_flow',
            conditions: [
              'bladder_health',
              'bowel_health',
              'chronic_pelvic_pain',
              'menopause',
              'pelvic_organ_prolapse',
              'postpartum',
              'pregnancy',
              'prevention',
              'sexual_health',
            ],
            enabled: false,
            exclusion_criteria: {},
            minimum_scheduling_distance_hours: 1,
            navigator_visible: false,
            number_of_days_to_schedule: 13,
            service_types: [
              'wellness',
            ],
            bypass_clinical_referral_rules: true,
            wheel_balance: 0,
            openloop_balance: 100,
            provider_minors_referral: 'OPENLOOP',
            on_call_chat: true,
            on_call_technical_launch_date: null,
            academy_enabled: true,
            cbt_program: false,
            credentialRequired: false,
            credential_group_id: null,
            show_on_navigator: true,
            support_email: '',
            toggles: {
              show_vaginal_anatomy_question_on_onboarding: true,
              allow_no_pod_sessions: true,
              allow_minor_enrollments: false,
              bypass_clinical_referral_rules: true,
              bypass_pt_regulation_status: true,
              can_skip_video_call: false,
              care_coordinator_enabled: false,
              conservative_mode: false,
              enable_sendbird_video_call: false,
              kit_shipped_only_after_video_call: true,
              show_how_long_experiencing_pain_field: false,
              show_referrals: false,
              is_loki_enabled: true,
              finish_members_program: false,
            },
            loki: {
              phase_one_enrollment_percentage: 0,
            },
            finish_members_program: {
              scheduled_start_date: null,
            },
            video_call: {
              buffer: 5,
              duration: 15,
              pt_provider_duration: 0,
            },
            whitelist: null,
            whitelist_fields: [],
            onboarding_forms: {
              injury_areas: {},
            },
            conditionsMapping: [],
            institution_configs: {
              automatic_reassessments: true,
              send_sms_reminders: true,
            },
            technical_launch_date: null,
            marketing_launch_date: null,
            unique_addressable_lives: null,
            can_skip_video_call_percentage: 0,
            high_risk: {
              enabled: false,
              salesforce_account_id: '',
              marketing_launch_date: null,
            },
            marketing_preferences: {
              allow_free_language: false,
              member_reference_name: '',
              date_of_first_communication: '',
              requires_content_changes_approval: false,
              requires_aesthetic_changes_approval: false,
              eligibility_statement_toggle: false,
              eligibility_statement: '',
              marketing_access: '',
              channel_opt_in: [],
              disclaimer_toggle: false,
              disclaimer: '',
              allow_thematic_campaigns: false,
              sms_allowed: false,
              emails_allowed: false,
            },
            eligibility_criteria: null,
            implementation_status: 'in_implementation',
            benefits_enrolled_eligible: null,
            member_payment: null,
          },
          move: {
            capacity: 0,
            show_hub_tab: true,
            enabled: false,
            navigator_visible: false,
            on_call_chat: true,
            on_call_technical_launch_date: null,
            academy_enabled: true,
            allow_phone_call: false,
            cbt_program: false,
            credentialRequired: false,
            credential_group_id: null,
            show_on_navigator: false,
            support_email: '',
            toggles: {
              allow_minor_enrollments: true,
              bypass_clinical_referral_rules: true,
              bypass_pt_regulation_status: true,
              show_referrals: false,
            },
            institution_configs: {
              email_performances: false,
              email_professional_after_session: false,
              email_suggestions: false,
              send_sms_reminders: true,
            },
            technical_launch_date: null,
            marketing_launch_date: null,
            unique_addressable_lives: null,
            onboarding_type: 'enrollment_finish',
            high_risk: {
              enabled: false,
              salesforce_account_id: '',
              marketing_launch_date: null,
            },
            marketing_preferences: {
              allow_free_language: false,
              member_reference_name: '',
              date_of_first_communication: '',
              requires_content_changes_approval: false,
              requires_aesthetic_changes_approval: false,
              eligibility_statement_toggle: false,
              eligibility_statement: '',
              marketing_access: '',
              channel_opt_in: [],
              disclaimer_toggle: false,
              disclaimer: '',
              allow_thematic_campaigns: false,
              sms_allowed: false,
              emails_allowed: false,
            },
            eligibility_criteria: null,
            implementation_status: 'in_implementation',
            benefits_enrolled_eligible: null,
            member_payment: null,
          },
          mind: {
            capacity: 0,
            show_hub_tab: true,
            call_type: 'treatment_flow',
            enabled: false,
            allow_phone_call: false,
            exclusion_criteria: {},
            minimum_scheduling_distance_hours: 4,
            navigator_visible: false,
            number_of_days_to_schedule: 15,
            service_types: [],
            on_call_chat: false,
            on_call_technical_launch_date: null,
            academy_enabled: false,
            credentialRequired: false,
            credential_group_id: null,
            show_on_navigator: true,
            support_email: '',
            toggles: {
              allow_minor_enrollments: false,
              bypass_clinical_referral_rules: true,
              bypass_pt_regulation_status: true,
              can_skip_video_call: false,
              care_coordinator_enabled: false,
              conservative_mode: false,
              enable_sendbird_video_call: false,
              show_referrals: false,
            },
            video_call: {
              buffer: 5,
              duration: 15,
              pt_provider_duration: 0,
            },
            whitelist: null,
            whitelist_fields: [],
            conditionsMapping: [],
            institution_configs: {
              automatic_reassessments: true,
              send_sms_reminders: true,
            },
            technical_launch_date: null,
            marketing_launch_date: null,
            unique_addressable_lives: null,
            marketing_preferences: {
              allow_free_language: false,
              member_reference_name: '',
              date_of_first_communication: '',
              requires_content_changes_approval: false,
              requires_aesthetic_changes_approval: false,
              eligibility_statement_toggle: false,
              eligibility_statement: '',
              marketing_access: '',
              channel_opt_in: [],
              disclaimer_toggle: false,
              disclaimer: '',
              allow_thematic_campaigns: false,
              sms_allowed: false,
              emails_allowed: false,
            },
            eligibility_criteria: null,
            implementation_status: 'in_implementation',
            benefits_enrolled_eligible: null,
            member_payment: null,
          },
          dpt_go: {
            enabled: false,
            conditions: [],
            on_call_chat: false,
            on_call_technical_launch_date: null,
            academy_enabled: true,
            cbt_program: false,
            support_email: '',
            institution_configs: {
              sword_go_enabled: true,
            },
            marketing_preferences: {
              allow_free_language: false,
              member_reference_name: '',
              date_of_first_communication: '',
              requires_content_changes_approval: false,
              requires_aesthetic_changes_approval: false,
              eligibility_statement_toggle: false,
              eligibility_statement: '',
              marketing_access: '',
              disclaimer_toggle: false,
              disclaimer: '',
              channel_opt_in: [],
              allow_thematic_campaigns: false,
              sms_allowed: false,
              emails_allowed: false,
            },
            eligibility_criteria: null,
            implementation_status: 'in_implementation',
            benefits_enrolled_eligible: null,
            member_payment: null,
          },
          no_program: {
            show_hub_tab: true,
            enabled: true,
            preventive_chat_enabled: false,
            on_call_chat: true,
            on_call_technical_launch_date: null,
            academy_enabled: true,
            implementation_status: 'in_implementation',
          },
          triage_helper: {
            enabled: false,
          },
        },
        active: true,
      },
    };
  },
  beforeMount() {
    this.isRevisionRequestsRequired = this.$store.getters['Core/isRevisionRequestsEnabled'];
    this.editing = !!this.$route.params.clientID;
    if (this.editing) {
      this.client_id = this.$route.params.clientID;
      this.fetchClient();
    }

    if (this.$route.query.institution) {
      this.fetchInstitution(this.$route.query.institution);
    }
    this.fetchInjuryAreas();
    this.fetchClients();
    this.fetchCredentialGroups();
    if (!this.client.configuration.institution_timezone) {
      this.client.configuration.institution_timezone = moment.tz.guess();
    }
    this.fetchSupportContact();
    this.getAvailableLanguages();
    this.fetchCountries();

    const { activeTab } = this.$route.query;
    if (activeTab) {
      this.activeTab = activeTab;
    }
  },
  computed: {
    allTabsValid() {
      return !Object.values(this.tabs)
        .find(t => !t.isValid);
    },
    overlay() {
      // https://icons.getbootstrap.com/
      // https://bootstrap-vue.org/docs/icons#animated-icons
      // Loading
      if (this.processStatus.client.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching client',
        };
      }
      if (this.processStatus.institution.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching institution',
        };
      }
      if (this.processStatus.clients.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching client list',
        };
      }
      if (this.processStatus.injuryAreas.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching injury areas',
        };
      }

      // Fetch error
      if (this.processStatus.client.fetchError) {
        return {
          show: true,
          icon: 'bug',
          animation: 'spin',
          text: 'Error fetching client',
        };
      }
      if (this.processStatus.institution.fetchError) {
        return {
          show: true,
          icon: 'bug',
          animation: 'spin',
          text: 'Error fetching institution',
        };
      }
      if (this.processStatus.clients.fetchError) {
        return {
          show: true,
          icon: 'bug',
          animation: 'spin',
          text: 'Error fetching clients',
        };
      }
      if (this.processStatus.injuryAreas.fetchError) {
        return {
          show: true,
          icon: 'bug',
          animation: 'spin',
          text: 'Error fetching injuryAreas',
        };
      }

      // Saving
      if (this.processStatus.institution.saving) {
        return {
          show: true,
          icon: 'save',
          animation: 'throb',
          text: 'Saving institution',
        };
      }
      if (this.processStatus.client.saving) {
        return {
          show: true,
          icon: 'save',
          animation: 'throb',
          text: 'Saving client',
        };
      }

      return {
        show: false,
        icon: '',
        animation: '',
        text: '',
      };
    },
    floatingButtons() {
      if ((this.isRevisionRequestsRequired && this.editing)
        || this.processStatus.client.saving
        || this.processStatus.institution.saving
        || this.processStatus.client.loading
        || this.processStatus.clients.loading
        || this.processStatus.institution.loading
        || this.processStatus.injuryAreas.loading
      ) {
        return {};
      }

      if (!this.allTabsValid) {
        return {
          save: {
            icon: 'save',
            text: 'Save',
            color: 'warning',
            disabled: true,
            title: 'Save disabled. Form is invalid.',
          },
        };
      }
      return {
        save: {
          icon: 'save',
          text: 'Save',
          color: 'primary',
        },
      };
    },
  },
  watch: {
    'client.data_access': 'switchSupportContacts',
    'client.configuration.toggles.show_get_started_pages': 'disableGetStartedForSSO',
  },
  methods: {
    getAvailableLanguages() {
      const language = appConfigs.get('CLIENT_DEFAULT_VIDEO_CALL_LANGUAGES');
      if (typeof language === 'undefined' || language === '') {
        this.client.configuration.available_languages = [ 'en' ];
      } else {
        this.client.configuration.available_languages = language.split(',');
      }
    },
    fetchCountries() {
      const selectedEnv = this.$store.getters['Core/getSelectedEnv'];
      switch (selectedEnv) {
        case 'eu':
          this.client.configuration.country = 'pt';
          break;
        case 'uk':
          this.client.configuration.country = 'gb';
          break;
        case 'ca' || 'au':
          this.client.configuration.country = selectedEnv;
          break;
        default:
          this.client.configuration.country = 'us';
          break;
      }
    },
    disableGetStartedForSSO() {
      this.client.configuration.toggles.show_get_started_pages_for_sso = this.client.configuration.toggles.show_get_started_pages
        ? false
        : this.client.configuration.toggles.show_get_started_pages_for_sso;
    },
    switchSupportContacts() {
      const dataAccess = this.client.data_access;
      switch (dataAccess) {
        case 'normal':
          if (this.client.configuration.support_email === this.client.configuration.strictSupportEmail
            || !this.client.configuration.support_email) {
            this.client.configuration.support_email = this.client.configuration.normalSupportEmail;
            this.client.configuration.dpt.support_email = this.client.configuration.normalSupportEmail;
            this.client.configuration.bloom.support_email = this.client.configuration.normalBloomSupportEmail;
          }
          if (this.client.configuration.support_phone_number === this.client.configuration.strictSupportPhone
            || !this.client.configuration.support_phone_number) {
            this.client.configuration.support_phone_number = this.client.configuration.normalSupportPhone;
          }
          break;
        case 'strict':
          if (this.client.configuration.support_email === this.client.configuration.normalSupportEmail
            || !this.client.configuration.support_email) {
            this.client.configuration.support_email = this.client.configuration.strictSupportEmail;
            this.client.configuration.dpt.support_email = this.client.configuration.strictSupportEmail;
            this.client.configuration.bloom.support_email = this.client.configuration.strictBloomSupportEmail;
          }
          if (this.client.configuration.support_phone_number === this.client.configuration.normalSupportPhone
            || !this.client.configuration.support_phone_number) {
            this.client.configuration.support_phone_number = this.client.configuration.strictSupportPhone;
          }
          break;
        default:
          break;
      }
    },
    fetchSupportContact() {
      axios.get('v1/configs')
        .then(response => {
          this.client.configuration.normalSupportEmail = response.data
            .find(item => item.key === 'support_communications_configs_normal_support_email').value;
          this.client.configuration.strictSupportEmail = response.data
            .find(item => item.key === 'support_communications_configs_strict_support_email').value;
          this.client.configuration.normalBloomSupportEmail = response.data
            .find(item => item.key === 'support_communications_configs_bloom_normal_support_email').value;
          this.client.configuration.strictBloomSupportEmail = response.data
            .find(item => item.key === 'support_communications_configs_bloom_strict_support_email').value;
          this.client.configuration.normalSupportPhone = response.data
            .find(item => item.key === 'support_communications_configs_normal_support_phone').value;
          this.client.configuration.strictSupportPhone = response.data
            .find(item => item.key === 'support_communications_configs_strict_support_phone').value;

          this.switchSupportContacts();
        })
        .catch(e => {
          Vue.prototype.$noty.error('Cannot fetch support contact', e);
          this.processStatus.client.fetchError = true;
          console.error('Cannot fetch support contact', e);
        });
    },
    fetchClient() {
      if (!this.client_id) {
        return Promise.resolve();
      }
      this.processStatus.client.loading = true;
      this.processStatus.client.fetchError = false;
      return axios.get(`v1/clients/${this.client_id}`, { params: { by: 'id' } })
        .then(response => {
          this.client = R.mergeDeepRight(this.client, cleanupClientFields(response.data.client));
          this.client = R.mergeDeepRight(this.client, mapInjuryAreas(this.client, this.injuryAreaList, this.editing));
          if (this.editing) {
            this.client.configuration.dpt.wheel_balance = response.data.client.configuration.dpt.wheel_balance;
            this.client.configuration.dpt.openloop_balance = response.data.client.configuration.dpt.openloop_balance;
          }

          return Promise.all([
            this.fetchInstitution(this.client.institution_id),
            this.fetchLogoImages(),
          ]);
        })
        .then(() => {
          if (this.isRevisionRequestsRequired) {
            this.liveData = R.clone(this.client);
            this.showRevisionRequests = true;
          }
        })
        .catch(e => {
          Vue.prototype.$noty.error('Cannot fetch client data', e);
          this.processStatus.client.fetchError = true;
          console.error('Cannot fetch client data', e);
        })
        .finally(() => {
          this.processStatus.client.loading = false;
        });
    },
    fetchInstitution(id) {
      if (!id) {
        return Promise.resolve();
      }

      this.processStatus.institution.loading = true;
      this.processStatus.institution.fetchError = false;
      return axios.get(`v2/institutions/${id}`, { params: { client: 'web' } })
        .then(response => {
          this.client = mapInstitutionIntoClient(this.client, response.data.data);
        })
        .catch(e => {
          if (e.response && e.response.status === 404) {
            Vue.prototype.$noty.error('Institution not found, please select one institution to continue', e);
          } else {
            Vue.prototype.$noty.error('Cannot fetch institution data', e);
          }
          this.processStatus.institution.fetchError = false;
          console.error('Cannot fetch institution data', e);
        })
        .finally(() => {
          this.processStatus.institution.loading = false;
        });
    },
    fetchInjuryAreas() {
      this.processStatus.injuryAreas.loading = true;
      this.processStatus.injuryAreas.fetchError = false;
      return axios.get('v1/injury-areas/')
        .then(data => {
          this.injuryAreaList = Object.entries(data.data.injury_areas)
            .map(([ key, areaData ]) => {
              const units = areaData.is_bloom ? [ 'bloom' ] : [ 'dpt' ];

              return {
                key,
                label: Vue.options.filters.capitalize(key.replace(/_/g, ' ')),
                isBloom: areaData.is_bloom,
                enabled: areaData.preselected,
                units: areaData.units ? areaData.units : units,
              };
            });
          this.client = R.mergeDeepRight(this.client, mapInjuryAreas(this.client, this.injuryAreaList, this.editing));
        })
        .catch(e => {
          Vue.prototype.$noty.error('Cannot fetch injury areas', e);
          this.processStatus.injuryAreas.fetchError = true;
          console.error('Cannot fetch injury areas', e);
        })
        .finally(() => {
          this.processStatus.injuryAreas.loading = false;
        });
    },
    fetchClients() {
      this.processStatus.clients.loading = true;
      this.processStatus.clients.fetchError = false;
      return axios.get('v1/clients')
        .then(res => {
          this.clientList = res.data.payload;
        })
        .catch(e => {
          Vue.prototype.$noty.error('Cannot fetch clients', e);
          this.processStatus.clients.fetchError = true;
          console.error('Cannot fetch clients', e);
        })
        .finally(() => {
          this.processStatus.clients.loading = false;
        });
    },
    fetchCredentialGroups() {
      return axios.get('v1/credential-groups')
        .then(({ data }) => {
          this.credentialGroupsList = data.map(el => ({
            value: el.id,
            text: el.name,
          }));
        });
    },
    fetchLogoImages() {
      if (!this.editing) {
        return Promise.resolve();
      }

      return axios.get(`/v3/clients/${this.client_id}/logo`)
        .then(response => {
          this.client.logo.available_logos = [];
          response.data.items.forEach(clientLogo => {
            if (clientLogo.is_current) {
              this.client.logo.current = clientLogo.id;
            }

            this.client.logo.available_logos.push(clientLogo);
          });
        })
        .catch(error => {
          this.client.logo.available_logos = [];
          console.error(error);
          this.$noty.error('Error while trying to fetch client logo images', error);
        });
    },
    saveClient() {
      this.processStatus.client.saving = true;
      const formData = new FormData();
      formData.append('onboarding_config_forms', '');
      this.client.logo.selected.forEach(file => {
        formData.append('files', file);
      });

      const client = mapClientDataForSave(this.client, this.editing);

      Object.keys(client)
        .forEach(key => {
          const formValue = typeof client[key] === 'object' ? JSON.stringify(client[key]) : client[key];
          formData.append(key, formValue);
        });

      if (this.editing) {
        return axios.put(`/v3/clients/${this.client_id}`, formData)
          .then(() => {
            Vue.prototype.$noty.success('Client saved successfully');
            this.fetchClient();
          })
          .catch(err => {
            Vue.prototype.$noty.error(`Failed to save the client, ${err.response?.data?.message || ''}`);
            console.error('err', err);
          })
          .finally(() => {
            this.processStatus.client.saving = false;
          });
      }
      return axios.post('/v3/clients', formData)
        .then(rsp => {
          Vue.prototype.$noty.success('Client saved successfully');
          this.$router.push(`/onboarding/client/edit/${rsp.data.payload.id}`);
          // force redirect
          this.$router.go();
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to save the client, ${err.response?.data?.message || ''}`);
          console.error('err', err);
          this.processStatus.client.saving = false;
        });
    },
    saveInstitution() {
      this.processStatus.institution.saving = true;
      const institution = createInstitutionDataForSave(this.client);

      let method = 'post';
      let url = 'v2/institutions/';

      if (this.editing || institution.institutionID) {
        method = 'put';
        url += institution.institutionID;
        delete institution.unique_key;
      }

      return axios.request({
        method,
        url,
        data: institution,
      })
        .then(r => {
          Vue.prototype.$noty.success('Institution saved successfully');
          const { institutionID } = r.data.data;
          return this.fetchInstitution(institutionID);
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to save institution, ${this.getErrorDetail(err)}`);
          console.error('Failed to save institution', err);
          throw err;
        })
        .finally(() => {
          this.processStatus.institution.saving = false;
        });
    },
    validateDPTReferralsDistribution() {
      const wheelBalance = this.client.configuration.dpt.wheel_balance;
      const openloopBalance = this.client.configuration.dpt.openloop_balance;

      if (!Number.isInteger(wheelBalance) || !Number.isInteger(openloopBalance)) {
        throw new Error('Thrive: Referral must be integers');
      }

      if ((wheelBalance !== '' && openloopBalance !== '')
        && wheelBalance + openloopBalance !== 100
        && wheelBalance + openloopBalance !== 0) {
        throw new Error('Thrive: Referral sum must be 100% or 0%');
      }

      if ((wheelBalance !== '' && openloopBalance === '')
        || (wheelBalance === '' && openloopBalance !== '')) {
        throw new Error('Thrive: Invalid referral values');
      }

      return true;
    },
    validateBloomReferralsDistribution() {
      const wheelBalance = this.client.configuration.bloom.wheel_balance;
      const openloopBalance = this.client.configuration.bloom.openloop_balance;

      if (!Number.isInteger(wheelBalance) || !Number.isInteger(openloopBalance)) {
        throw new Error('Bloom: Referral must be integers');
      }

      if ((wheelBalance !== '' && openloopBalance !== '')
        && wheelBalance + openloopBalance !== 100
        && wheelBalance + openloopBalance !== 0) {
        throw new Error('Bloom: Referral sum must be 100% or 0%');
      }

      if ((wheelBalance !== '' && openloopBalance === '')
        || (wheelBalance === '' && openloopBalance !== '')) {
        throw new Error('Bloom: Invalid referral values');
      }
    },
    async save() {
      if (!this.editing) {
        this.client.name = this.client.reference;
      }

      try {
        this.validateDPTReferralsDistribution();
        this.validateBloomReferralsDistribution();

        await this.saveInstitution();
        await this.saveClient();
      } catch (e) {
        Vue.prototype.$noty.error(e?.message ?? e);
        console.error('Failed to save Client / Institution', e?.message ?? e);
      } finally {
        this.processStatus.institution.saving = false;
        this.processStatus.client.saving = false;
      }
    },
    onUploadImagesClicked() {
      this.processStatus.client.saving = true;

      return this.uploadLogoImages()
        .then(response => {
          this.client.logo.selected = [];
          this.client.logo.available_logos = response.data.items;
        })
        .catch(error => {
          this.$noty.error(`Error trying to upload logo images: ${error?.message}`);
        })
        .finally(() => {
          this.processStatus.client.saving = false;
        });
    },
    uploadLogoImages() {
      const formData = new FormData();
      this.client.logo.selected.forEach(file => {
        formData.append('files', file);
      });

      return axios.post(`/v3/clients/${this.client_id}/logo`, formData);
    },
    onStartSaving() {
      this.processStatus.client.saving = true;
    },
    onFinishSaving() {
      this.processStatus.client.saving = false;
    },
    mapClientToRevisionRequest(clientData, opts = {}) {
      let client = null;
      let institution = null;

      if (!this.editing) {
        clientData.name = clientData.reference;
      }

      try {
        const ignoreErrors = opts.ignore_errors === true;
        client = R.clone(mapClientDataForSave(clientData, this.editing, ignoreErrors));
        institution = R.clone(createInstitutionDataForSave(clientData));
      } catch (e) {
        Vue.prototype.$noty.error(e.message);
        throw e;
      }

      return {
        client,
        institution,
      };
    },
    mapRevisionRequestToClient(rev) {
      const {
        institution,
        client,
      } = rev;
      let parsed = null;

      try {
        parsed = R.mergeDeepRight(this.client, cleanupClientFields(client));
        parsed = R.mergeDeepRight(parsed, mapInjuryAreas(client, this.injuryAreaList, this.editing));
        parsed = mapInstitutionIntoClient(parsed, institution, true);
      } catch (e) {
        Vue.prototype.$noty.error(e.message);
        throw e;
      }

      return parsed;
    },
    onSelectedRevisionRequestChanged(parsedRev) {
      this.client = R.clone(!parsedRev ? this.liveData : parsedRev);
    },
    onRevisionRequestPublished() {
      this.liveData = R.clone(this.client);
      this.fetchClient();
    },
    getErrorDetail(err) {
      let detail = '';
      if (err.response && err.response.data) {
        if (err.response.data.error_code) {
          detail += `error_code: ${err.response.data.error_code} \n`;
        }

        if (err.response.data.error_description) {
          detail += `error_description: ${R.head(R.values(err.response.data.error_description))}`;
        }
      }
      return detail;
    },
  },
};
</script>

<template>
  <b-container class="mt-4 edit-client-page" fluid>
    <b-row>
      <b-col cols="12">
        <h5 v-if="editing">
          Edit client {{ client.display_name }}
          <b-badge variant="success" v-if="client.active">active</b-badge>
          <b-badge variant="warning" v-else>inactive</b-badge>
        </h5>
        <h5 v-else>Creating new client</h5>
      </b-col>
    </b-row>

    <RevisionRequest
      v-if="this.isRevisionRequestsRequired && this.showRevisionRequests"
      entityType="client"

      :currentData="this.client"
      :entityId="this.client.id"
      :hiddenFields="this.revisionHiddenFields"
      :isFromValid="this.allTabsValid"
      :liveData="this.liveData"
      :mapToEntity="mapRevisionRequestToClient"
      :mapToRevisionRequest="mapClientToRevisionRequest"

      @selected-revision-request-changed="onSelectedRevisionRequestChanged"
      @start-saving="onStartSaving"
      @finish-saving="onFinishSaving"
      @new-revision-request-published="onRevisionRequestPublished">
    </RevisionRequest>

    <b-overlay :show="overlay.show">
      <template #overlay>
        <div class="text-center">
          <b-icon :animation="overlay.animation" :icon="overlay.icon" font-scale="3"></b-icon>
          <p id="cancel-label">{{ overlay.text }}</p>
        </div>
      </template>
      <b-card no-body>
        <b-tabs card pills vertical v-model="currentTab">
          <TabStatus v-if="editing" :active-tab="activeTab" :client="client" :currentTab="currentTab" :editing="editing"/>
          <TabGeneral :client="client" :currentTab="currentTab" :client-list="clientList" :editing="editing" :tab="tabs.general"/>
          <TabEnrollment :client="client" :currentTab="currentTab" :editing="editing" :tab="tabs.design"
                         @upload-images-clicked="onUploadImagesClicked"/>
          <TabLocalization :client="client" :currentTab="currentTab" :editing="editing" :tab="tabs.localization"/>
          <TabLegal :client="client" :currentTab="currentTab" :editing="editing" :tab="tabs.legal"/>
          <TabMarketing :client="client" :currentTab="currentTab" :editing="editing" :tab="tabs.marketing"/>
          <TabEcosystemVendors :active-tab="activeTab" :client="client" :currentTab="currentTab" />

          <TabDPT :client="client" :currentTab="currentTab" :credential-groups-list="credentialGroupsList" :editing="editing"
                  :injury-area-list="injuryAreaList" :tab="tabs.dpt" />
          <TabBloom :client="client" :currentTab="currentTab" :editing="editing" :injury-area-list="injuryAreaList"
                    :credential-groups-list="credentialGroupsList" :tab="tabs.bloom" />
          <TabMove :client="client" :currentTab="currentTab" :editing="editing" :tab="tabs.move" />
          <TabMind v-if="this.$isSudo" :client="client" :currentTab="currentTab" :editing="editing" :tab="tabs.mind" />
          <TabDPTGo :client="client" :currentTab="currentTab" :editing="editing" :injury-area-list="injuryAreaList" :tab="tabs.dpt_go" />
          <TabNoProgram :client="client" :currentTab="currentTab" :editing="editing" :tab="tabs.noProgram" />

          <TabExtraConfiguration v-if="client_id" :client="client" :currentTab="currentTab" :editing="editing" />
        </b-tabs>
      </b-card>
    </b-overlay>

    <FloatingButtons :buttons="floatingButtons" v-on:floating-button-click="save" />
  </b-container>
</template>

<style lang="scss" scoped>
.edit-client-page::v-deep {
  .nav-pills {
    .nav-item {
      width: 170px;
    }
  }
}
</style>
