<script>
import {
  required,
  integer,
  minLength,
  requiredIf,
} from 'vuelidate/lib/validators';
import helpers from '@/helpers';
import {
  InstitutionCategory,
  institutionIndustryBroadOptions,
  institutionIndustrySubOptions,
  InstitutionType,
} from '@/scripts/definitions';
import pagination from '@/mixins/lists/pagination';
import AddNewSlugModal from './Slugs/AddNewSlugModal.vue';

// eslint-disable-next-line import/no-named-as-default-member
const { isOneOf, regexValidator } = helpers;

export default {
  name: 'TabGeneral',
  mixins: [ pagination ],
  components: {
    AddNewSlugModal,
  },
  props: {
    tab: Object,
    client: Object,
    clientList: Array,
    editing: Boolean,
  },
  data() {
    return {
      loadingPage: false,
      addSlugModalVisible: false,
      clientModeOptions: [
        { text: 'Normal', value: 'NORMAL' },
        { text: 'Test', value: 'TEST' },
        { text: 'Demo', value: 'DEMO' },
      ],
      industryBroadOptions: institutionIndustryBroadOptions.map(ib => ({ value: ib.optValue, text: ib.optLabel })),
      institutionCategoryOptions: InstitutionCategory.map(ic => ({ value: ic.optValue, text: ic.optLabel })),
      institutionTypeOptions: InstitutionType.map(it => ({ value: it.optValue, text: it.optLabel })),
      clientTierOptions: [
        { text: 'Tier 1', value: 'Tier 1' },
        { text: 'Tier 2', value: 'Tier 2' },
        { text: 'Tier 3', value: 'Tier 3' },
        { text: 'Tier 4', value: 'Tier 4' },
      ],
      businessTypeOptions: [
        { text: 'Employer', value: 'employer' },
        { text: 'Union', value: 'union' },
        { text: 'Provider', value: 'provider' },
        { text: 'Health Plan', value: 'health_plan' },
        { text: 'Government Federal', value: 'government_federal' },
        { text: 'Government State/Local', value: 'government_state_local' },
        { text: 'TPA', value: 'tpa' },
        { text: 'International', value: 'international' },
        { text: 'Partnership', value: 'partnership' },
      ],
      officialContractingPartnerOptions: [
        { text: 'Health Plan', value: 'health_plan' },
        { text: 'Health Plan - Highmark', value: 'health_plan_highmark' },
        { text: 'Health Plan - Transcarent', value: 'health_plan_transcarent' },
        { text: 'Health Plan - Independence Blue cross', value: 'health_plan_independence_blue_cross' },
        { text: 'Health Plan - Independence Administrators', value: 'health_plan_independence_administrators' },
        { text: 'Health Plan - Presbyterian', value: 'health_plan_presbyterian' },
        { text: 'Partnership', value: 'partnership' },
        { text: 'Partnership - Amwell', value: 'partnership_amwell' },
        { text: 'Partnership - Accolade', value: 'partnership_accolade' },
        { text: 'Partnership - Navitus', value: 'partnership_navitus' },
        { text: 'Partnership - Willis Tower Watson', value: 'partnership_willis_tower_watson' },
        { text: 'Partnership - Businessolver', value: 'partnership_businessolver' },
        { text: 'Partnership - Apree Health (Castlight)', value: 'partnership_apree_health' },
        { text: 'Partnership - Virgin Pulse', value: 'partnership_virgin_pulse' },
        { text: 'Partnership - Quantum Health', value: 'partnership_quantum_health' },
        { text: 'Partnership - Other', value: 'partnership_other' },
        { text: 'Direct', value: 'direct' },
      ],
    };
  },
  computed: {
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
    parentClientOptions() {
      return [ { text: 'None', value: null } ].concat((this.clientList.map(client => ({
        text: `${client.display_name} (ID: ${client.id})`,
        value: client.id,
      }))).sort((a, b) => a.text.localeCompare(b.text)));
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
    'client.slugs': {
      handler(slugs) {
        slugs.forEach(slug => {
          if (slug.is_primary && !slug.enabled) {
            slug.enabled = true;
            this.$noty.error('A primary Slug cannot be disabled');
          }
          if (this.client.reference === slug.slug && !slug.enabled) {
            slug.enabled = true;
            this.$noty.error('A client reference cannot be disabled');
          }
        });
      },
      deep: true,
    },
  },
  validations() {
    return {
      client: {
        id: { req: requiredIf(() => this.editing), integer },
        reference: { required, regex: regexValidator(/([a-z]_*)+/g), minLength: minLength(2) },
        display_name: { required },
        institution_id: { req: requiredIf(() => this.editing), integer },
        rvp_name: { required },
        rvp_email: { required },
        parent_id: { integer },
        slugs: { checkPrimary: this.checkPrimarySlug },
        configuration: {
          client_mode: { required, oneOf: isOneOf(this.clientModeOptions) },
          industry_broad: { required, oneOf: isOneOf(this.industryBroadOptions) },
          industry_sub: { req: requiredIf(() => this.getIndustySubOptions()?.filter(v => v.value !== null).length > 0) },
          institution_category: { required, oneOf: isOneOf(this.institutionCategoryOptions) },
          institution_type: { required, oneOf: isOneOf(this.institutionTypeOptions) },
          salesforce_account_name: { req: requiredIf(() => !this.editing) },
        },
      },
    };
  },
  methods: {
    getIndustySubOptions() {
      const options = [];
      const industry = this.client.configuration.industry_broad;

      if (industry !== null && institutionIndustrySubOptions[industry]) {
        options.push(...institutionIndustrySubOptions[industry].map(ib => ({ value: ib.optValue, text: ib.optLabel })));
      }

      return options;
    },
    openAddSlugModal() {
      this.addSlugModalVisible = true;
    },
    onAddNewSlugModalClose() {
      this.addSlugModalVisible = false;
    },
    checkPrimarySlug(slugs) {
      const primarySlugs = slugs.filter(slug => slug.is_primary);
      if (slugs.length && !primarySlugs.length) {
        this.$noty.error('A primary slug is required');
        return false;
      }
      if (primarySlugs.length > 1) {
        this.$noty.error('A primary slug needs to be unique');
        return false;
      }
      return true;
    },
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1" :active="!editing">
    <template #title>
      General
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">

        <b-tab title="General">
          <b-row>
            <b-col cols="4">
              <b-form-group id="group-client-id" label="Client ID:"
                            label-for="input-client-id" description="">
                <b-form-input id="input-client-id" v-model="client.id"
                              type="text" placeholder="Client ID" disabled
                              required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-salesforce_account_name" label="Account Name:"
                            label-for="input-client-salesforce_account_name" description="">
                <template #label>
                  *Account Name:
                  <b-badge variant="primary" pill class="float-right"
                           v-b-tooltip.hover title="Should match the account name on SalesForce">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <b-form-input id="input-client-salesforce_account_name"
                              v-model.trim="client.configuration.salesforce_account_name"
                              type="text" placeholder="" required
                              :state="$v.client.configuration.salesforce_account_name.$invalid ? false : null">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-display_name" label="*Client Name:"
                            label-for="input-client-display_name" description="Visible on the onboarding page">
                <template #label>
                  *Client Name:
                  <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                           title="Visible on onboarding page">
                    <feather type="info"/>
                  </b-badge>
                </template>

                <b-form-input id="input-client-display_name" v-model.trim="client.display_name"
                              :state="$v.client.display_name.$invalid ? false : null"
                              type="text" placeholder="Client Name"
                              required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-reference" label="*Client Key:"
                            label-for="input-client-reference" description="Unchangeable, used in the onboarding URL">
                <template #label>
                  *Client Key:
                  <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                           title="Do not use spaces - use an underscore if needed">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <b-form-input id="input-client-reference" v-model.trim="client.reference" :disabled="editing"
                              type="text" placeholder="URL Path" :state="$v.client.reference.$invalid ? false : null"
                              required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-display_name" label="*Parent Client:"
                            label-for="input-client-parent_id" description="">
                <template #label>
                  *Parent Client:
                  <b-badge variant="primary" pill class="float-right"
                           v-b-tooltip.hover
                           title="If this client has a hierarchy in place in AdminUI, select the Parent Client from the clients list">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <b-form-select v-model="client.parent_id" :options="parentClientOptions" :disabled="!$isSudo"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-institution_id" label="Institution ID:"
                            label-for="input-client-institution_id" description="">
                <b-form-input id="input-client-institution_id" v-model.trim="client.institution_id"
                              type="text" placeholder="Institution ID" disabled
                              required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-configuration.onboarding_version" label="Onboarding Version:"
                            label-for="input-client-configuration.onboarding_version" description="">
                <b-form-input id="input-client-configuration.onboarding_version"
                              v-model.trim="client.configuration.onboarding_version"
                              type="text" placeholder="" disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-display_mode" label="*Client Mode:"
                            label-for="input-client-configuration-client_mode" description="">
                <b-form-select v-model="client.configuration.client_mode" :options="clientModeOptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4"/>
            <b-col cols="4 mt-3" v-if="$isSudo">
              <b-form-group>
                <template #label>
                  Create orders even if it is a test client:
                </template>
                <toggle-button sync id="checkbox-client-toggles.can_create_order_client_test"
                              v-model="client.configuration.toggles.can_create_order_client_test"
                              name="checkbox-client-toggles.can_create_order_client_test"/>
              </b-form-group>
            </b-col>
            <b-col cols="4 mt-3" v-if="$isSudo">
              <b-form-group>
                <template #label>
                  Disable Order Management:
                  <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                           title="When toggle is active, features for auto/manual order creation are disabled">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <toggle-button sync id="checkbox-client-toggles.order_management_disabled"
                              v-model="client.configuration.toggles.order_management_disabled"
                              name="checkbox-client-toggles.order_management_disabled"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Support">
          <b-row>
            <b-col cols="4">
              <b-form-group id="group-client-support_email" label="Support Email:"
                            label-for="input-client-support_email" description="">
                <b-form-input id="input-client-support_email" v-model.trim="client.configuration.support_email"
                              type="text" placeholder="" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-support_phone_number" label="Support Phone Number:"
                            label-for="input-client-support_phone_number" description="">
                <b-form-input id="input-client-support_phone_number" v-model.trim="client.configuration.support_phone_number"
                              type="text" placeholder="" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-rvp_email" label="*Enrollment Email:"
                            label-for="input-client-rvp_email" description="">
                <b-form-input id="input-client-rvp_email" v-model.trim="client.rvp_email"
                              :state="$v.client.rvp_email.$invalid ? false : null"
                              type="text" placeholder="" required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group id="group-client-support_eligibility_poc_name" label="Eligibility POC Name:"
                            label-for="input-client-eligibility-poc-name" description="">
                <b-form-input id="input-client-support_eligibility_poc_name" v-model.trim="client.configuration.support_eligibility_poc_name"
                              type="text" placeholder="" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-support_eligibility_poc_email" label="Eligibility POC Email:"
                            label-for="input-client-support_eligibility_poc_email" description="">
                <b-form-input id="input-client-support_eligibility_poc_email" v-model.trim="client.configuration.support_eligibility_poc_email"
                              type="text" placeholder="" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-support_customer_success_manager" label="Customer Success Manager:"
                  label-for="input-client-support-customer_success_manager" description="">
                <b-form-input id="input-client-support_customer_success_manager" v-model.trim="client.rvp_name"
                              :state="$v.client.rvp_name.$invalid ? false : null"
                              type="text" placeholder="" required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Contract">
          <b-row>
            <b-col cols="12" xl="4">
              <b-form-group id="group-client-client_phone" label="Client email:"
                            label-for="input-client-client_phone" description="">
                <b-form-input id="input-client-client_phone" v-model.trim="client.configuration.client_email"
                              type="text"></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-client_phone" label="Client phone:"
                            label-for="input-client-client_phone" description="">
                <b-form-input id="input-client-client_phone" v-model.trim="client.configuration.client_phone"
                              type="text"></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-client_web_address" label="Client web address:"
                            label-for="input-client-web_address" description="">
                <b-form-input id="input-client-web_address" v-model.trim="client.configuration.client_web_address"
                              type="text"></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-client_address" label="Client Address:"
                            label-for="input-client-client_address" description="">
                <b-form-input id="input-client-client_address" v-model.trim="client.configuration.client_address"
                              type="text"></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-business_type" label="Business Type:"
                            label-for="input-client-configuration-business-type" description="">
                <b-form-select v-model="client.configuration.business_type" :options="businessTypeOptions"></b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
              <b-form-group id="group-client-industry_broad" label="*Industry Broad:"
                            label-for="input-client-configuration-industry_broad" description="">
                <b-form-select v-model="client.configuration.industry_broad"
                               :options="industryBroadOptions"
                               :state="$v.client.configuration.industry_broad.$invalid ? false : null"></b-form-select>
              </b-form-group>
              <b-form-group id="group-client-industry_sub" label="*Industry Sub:"
                            label-for="input-client-configuration-industry_sub" description="">
                <b-form-select v-model="client.configuration.industry_sub"
                               :options="getIndustySubOptions()"
                               :state="$v.client.configuration.industry_sub.$invalid ? false : null"></b-form-select>
              </b-form-group>
              <b-form-group id="group-client-institution_category" label="*Institution Category:"
                            label-for="input-client-configuration-institution_category" description="">
                <b-form-select v-model="client.configuration.institution_category"
                               :options="institutionCategoryOptions"
                               :state="$v.client.configuration.institution_category.$invalid ? false : null"></b-form-select>
              </b-form-group>
              <b-form-group id="group-client-institution_type" label="*Institution Type:"
                            label-for="input-client-configuration-institution_type" description="">
                <b-form-select v-model="client.configuration.institution_type"
                               :options="institutionTypeOptions"
                               :state="$v.client.configuration.institution_type.$invalid ? false : null"></b-form-select>
              </b-form-group>
              <b-form-group id="group-client-official_contracting_partner" label="Official Contracting Partner:"
                              label-for="input-client-configuration-official-contracting-partner" description="">
                  <b-form-select
                    v-model="client.configuration.official_contracting_partner"
                    :options="officialContractingPartnerOptions">
                  </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
              <b-form-group id="group-client-salesforce_account_id" label="18 Digit Account ID:"
                            label-for="input-client-salesforce_account_id" description="">
                <template #label>
                  18 Digit Account ID:
                  <b-badge variant="primary" pill class="float-right"
                           v-b-tooltip.hover title="Found in Salesforce">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <b-form-input id="input-client-salesforce_account_id"
                              v-model.trim="client.configuration.salesforce_account_id"
                              type="text" placeholder="" required></b-form-input>
              </b-form-group>
              <b-form-group v-if="$isSudo"  id="group-client-salesforce_parent_account_id" label="Parent 18 Digit Account ID:"
                            label-for="input-client-salesforce_parent_account_id" description="">
                <template #label>
                  Parent 18 Digit Account ID:
                  <b-badge variant="primary" pill class="float-right"
                           v-b-tooltip.hover title="Found in Salesforce">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <b-form-input id="input-client-salesforce_parent_account_id"
                              v-model.trim="client.configuration.salesforce_parent_account_id"
                              type="text" placeholder=""></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-client_tier" label="Client Tier:"
                            label-for="input-client-configuration-client_tier" description="">
                <b-form-select v-model="client.configuration.client_tier" :options="clientTierOptions"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

        </b-tab>

        <b-tab title="URLs" v-if="editing">
          <b-row>
            <section id="vendor-list-module">
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <h6 class="my-3">Client Slug list</h6>

                  <b-button variant="primary" class="ml-auto" @click="openAddSlugModal">
                    Add Slug
                  </b-button>
                </div>
              </div>
              <table class="table -mx_0 -mb_1">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Primary</th>
                    <th scope="col">Enable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="slug.id" :slug="slug" v-for="slug in client.slugs">
                    <td class="align-middle"> {{ slug.slug }}</td>
                    <td class="align-middle" >
                        <toggle-button id="checkbox-client-slugs-enabled" v-if="!slug.is_primary" v-model="slug.enabled"
                                name="checkbox-client-slugs-enabled"
                                :readyOnly="true"
                                sync/>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row pt-2">
                <div class="col-12 d-flex justify-content-center">
                  <b-pagination :disabled="loadingPage" @change="changePage" v-model="pagination.currentPage"
                    :total-rows="pagination.totalRows" :per-page="pagination.itemsPerPage" size="md">
                  </b-pagination>
                </div>
              </div>
            </section>
          </b-row>
          <add-new-slug-modal :client="client" :visible="addSlugModalVisible" @close="onAddNewSlugModalClose" />
        </b-tab>

      </b-tabs>
    </b-card-text>
  </b-tab>
</template>
