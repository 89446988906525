<script>
import {
  required, requiredIf, between, numeric,
} from 'vuelidate/lib/validators';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { VIDEO_CALL_PROVIDERS, ENROLLMENT_CHAT_PROVIDERS } from '@/scripts/constants';
import { d2cOptions } from '@/scripts/definitions';

export default {
  name: 'TabEnrollment',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
  },
  data() {
    return {
      customTitleHint: 'Registration page will use a specific translation for the client. '
        + 'Translation key eco_account_creation_step1_title_[client_key]',
      onboardingFlowOptions: [ { text: '', value: null } ],
    };
  },
  beforeMount() {
    this.fetchOnboardingFlowsOptions();
  },
  computed: {
    ...mapGetters({
      isZoomProviderEnabled: 'Core/isZoomProviderEnabled',
      isSwagStoreEnabled: 'Core/isSwagStoreEnabled',
    }),
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
    logoOptions() {
      if (this.client.logo.available_logos.length <= 0) {
        return [
          {
            value: null,
            text: `${this.editing ? 'Upload' : 'Select'} images to change logo`,
            disabled: true,
          },
        ];
      }

      const options = [];
      if (!this.client.logo.current) {
        options.push({
          text: 'You must select a client logo',
          value: null,
          disabled: true,
        });
      }

      this.client.logo.available_logos.forEach(logoImg => options.push({
        text: logoImg.display_name,
        value: logoImg.id,
      }));

      return options;
    },
    uploadLogoImagesDisabled() {
      return (this.client.logo.selected.length <= 0);
    },
    currentLogoURL() {
      return this.client.logo.available_logos.find(({ id }) => (id === this.client.logo.current)).url;
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    formatVideoCallProvidersOptions() {
      const defaultOptions = [
        { value: VIDEO_CALL_PROVIDERS.SYSTEM, text: 'System' },
        { value: VIDEO_CALL_PROVIDERS.SENDBIRD, text: 'Sendbird' },
      ];

      if (this.isZoomProviderEnabled) {
        return defaultOptions.concat({ value: VIDEO_CALL_PROVIDERS.ZOOM, text: 'Zoom Unique Link' });
      }

      return defaultOptions;
    },
    formatEnrollmentChatProvidersOptions() {
      return [
        { value: ENROLLMENT_CHAT_PROVIDERS.ZENDESK, text: 'Zendesk' },
        { value: ENROLLMENT_CHAT_PROVIDERS.SWORDDESK, text: 'SwordDesk' },
      ];
    },
    formatD2COptions() {
      return d2cOptions;
    },
    fetchOnboardingFlowsOptions() {
      axios.get('v1/clients/onboarding-flows').then(res => {
        res.data.data.forEach(onboardingFlow => {
          this.onboardingFlowOptions.push({ value: onboardingFlow.uuid, text: onboardingFlow.title });
        });
      }).catch(err => {
        this.$noty.error('Cannot fetch onboarding flows', err);
        console.error('Cannot fetch onboarding flows', err);

        this.onboardingFlowOptions = [];
      });
    },
  },
  validations() {
    return {
      client: {
        logo: {
          current: { req: requiredIf(() => this.editing) },
          selected: { req: requiredIf(() => !this.editing) },
        },
        configuration: {
          toggles: {
            require_subscriber_dependent_information: { required },
          },
          enrollment_chat_show_percentage: {
            between: between(0, 100),
            numeric,
            required,
          },
        },
      },
    };
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      Enrollment
      <feather :type="tabIcon" class="float-right"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">
        <b-tab title="Logo">
          <b-row>
            <b-col cols="6">
              <div>
                <b-form-group v-if="this.editing" label="Current logo" label-for="current-logo" label-class="font-weight-bold">
                  <b-form-select id="current-logo" v-model="client.logo.current" :options="logoOptions" :state="(!!client.logo.current)"/>
                </b-form-group>
              </div>

              <b-card bg-variant="light">
                <b-form-group class="mb-0">
                  <b-row class="mb-1">
                    <b-col>
                      <label><strong>Upload image</strong></label>
                      <b-badge v-if="this.editing" id="logo_img_upload_help" class="float-right" variant="primary" v-b-tooltip.hover pill>
                        <feather type="info" />
                      </b-badge>
                      <b-tooltip v-if="this.editing" target="logo_img_upload_help" triggers="hover">
                        This field is <b>not</b> subject to revision requests. When clicking "Upload image",
                        the selected images will be uploaded immediately, but in order to update "Current logo",
                        a revision request must be created.
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-file v-model="client.logo.selected" accept=".png,.jpeg,.jpg,.svg" placeholder="Select file" multiple/>
                      <b-form-text>Allowed formats: png, jpeg, svg</b-form-text>
                    </b-col>

                    <b-col
                      v-if="editing"
                      cols="4">
                      <b-button variant="primary" class="align-text-bottom w-100" :disabled="uploadLogoImagesDisabled"
                                @click="$emit('upload-images-clicked')">Upload image</b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>

              <div class="mt-3">
                <label>
                  <toggle-button id="checkbox-client-hide_client_logo" v-model="client.configuration.toggles.hide_client_logo"
                                  name="checkbox-client-hide_client_logo" sync />
                  Hide client logo
                </label>
              </div>

              <div class="mt-3">
                <label>
                  <toggle-button id="checkbox-client-hide_sword_logo"
                                  v-model="client.configuration.toggles.hide_sword_logo"
                                  name="checkbox-client-hide_sword_logo"
                                  sync />
                  Hide Sword Logo
                </label>
              </div>
            </b-col>

            <b-col cols="6" class="text-center">
              <img v-if="client.logo.current" :src="currentLogoURL" alt="client logo" class="mb-3"
                   style="max-width: 10.5rem;">
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Experience">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-custom_client_title" v-model="client.configuration.custom_client_title"
                                   name="checkbox-client-custom_client_title" sync />
                    Custom title on registration page
                  </label>
                  <b-badge v-b-tooltip.hover :title="customTitleHint" class="float-right" pill variant="primary">
                    <feather type="info"/>
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-custom_navigator_titles" v-model="client.configuration.custom_navigator_titles"
                                   name="checkbox-client-custom_navigator_titles" sync />
                    Custom titles on navigator
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-navigator_hide_non_eligible_units"
                                   v-model="client.configuration.navigator_hide_non_eligible_units"
                                   name="checkbox-client-navigator_hide_non_eligible_units" sync />
                    Hide non eligible units on navigator
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-toggles.conservative_mode" v-model="client.configuration.toggles.conservative_mode"
                                   name="checkbox-client-toggles.conservative_mode" sync />
                    Conservative mode
                  </label>
                  <b-badge v-b-tooltip.hover class="float-right" pill title="Gender selection with only male and female"
                           variant="primary">
                    <feather type="info" />
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-show_member_id_field" v-model="client.configuration.toggles.show_member_id_field"
                                   name="checkbox-client-show_member_id_field" sync />
                    Show Health Insurance ID field
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-toggles.require_subscriber_dependent_information"
                                   v-model="client.configuration.toggles.require_subscriber_dependent_information"
                                   name="checkbox-client-toggles.require_subscriber_dependent_information" sync />
                    Require subscriber dependent information
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-toggles.show_relationship_field_on_onboarding"
                                   v-model="client.configuration.toggles.show_relationship_field_on_onboarding"
                                   name="checkbox-client-toggles.show_relationship_field_on_onboarding" sync />
                    Show relationship field when member is a dependent (mandatory field)
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-show_payers_list"
                                   v-model="client.configuration.toggles.show_payers_list"
                                   name="checkbox-client-show_payers_list" sync />
                    Show payers list
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-care_coordinator_enabled"
                                   v-model="client.configuration.toggles.care_coordinator_enabled"
                                   name="checkbox-client-care_coordinator_enabled" sync />
                    Care pathway on member invitation page
                  </label>
                  <b-badge v-b-tooltip.hover class="float-right" pill title="This is primarily for clients through Transcarent or Bridge Health,
                where we want Care Pathway options enabled in the Member Invitation process
                - OR if a client has a care coordinator filling out the onboarding for a member"
                           variant="primary">
                    <feather type="info" />
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-show_wizard" v-model="client.configuration.toggles.show_wizard"
                                   name="checkbox-client-show_wizard"
                                   sync />
                    Show wizard
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-always_show_navigator"
                                   v-model="client.configuration.toggles.always_show_navigator"
                                   name="checkbox-always_show_navigator"
                                   sync />
                    Always show navigator
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-hide_payer_insurance_fields"
                                   v-model="client.configuration.toggles.hide_payer_insurance_fields"
                                   name="checkbox-client-hide_payer_insurance_fields"
                                   sync />
                    Hide payer and insurance ID fields for members only eligible for services
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-enrollment_referrals"
                                   v-model="client.configuration.enrollment_referrals"
                                   name="checkbox-client-enrollment_referrals"
                                   sync />
                    Show referrals on success page
                  </label>
                </b-col>

                <b-col cols="12">
                  <label>
                    <toggle-button v-model="client.configuration.triage_helper.enabled"
                                   id="checkbox-client.configuration.triage_helper.enabled"
                                   name="checkbox-client.configuration.triage_helper.enabled" sync />
                    Triage/Helper enabled
                  </label>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="6">
              <b-form-group id="group-video-call-provider" label="Video Call Provider:">
                <b-select v-model="client.configuration.video_call_provider"
                          :options="formatVideoCallProvidersOptions()" />
              </b-form-group>
              <b-form-group id="group-enrollment-chat-provider" label="Enrollment Chat Provider:">
                <b-select v-model="client.configuration.enrollment_chat_provider"
                          :options="formatEnrollmentChatProvidersOptions()" />
              </b-form-group>
              <b-form-group id="group-client-configuration.enrollment-chat-show-percentage"
                            label="Percentage of enrollments to show support chat:"
                            label-for="input-client-configuration.enrollment-chat-show-percentage">
                <b-form-input id="input-client-configuration.enrollment-chat-show-percentage"
                              v-model.trim="client.configuration.enrollment_chat_show_percentage"
                              type="number" number></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-system_mode" label="System mode:"
                            label-for="input-client-system_mode">
                <b-form-radio v-model="client.configuration.system_mode"
                              name="system_mode" value="multi_member">Multi member
                </b-form-radio>
                <b-form-radio v-model="client.configuration.system_mode"
                              name="system_mode" value="single_member">Single member
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>


        <b-tab v-if="$isSudo">
          <template #title>
            Power over 9000
            <img alt="fire" src="@/assets/slack/slack-fire.png" height="20px"/>
          </template>
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-show_get_started_pages"
                                   v-model="client.configuration.toggles.show_get_started_pages"
                                   name="checkbox-client-show_get_started_pages"
                                   sync />
                    Show Get Started pages
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-show_get_started_pages_for_sso" :disabled="client.configuration.toggles.show_get_started_pages"
                                   v-model="client.configuration.toggles.show_get_started_pages_for_sso"
                                   name="checkbox-client-show_get_started_pages_for_sso"
                                   sync />
                    Show Get Started pages to SSO members
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dynamic_flow" :disabled="true"
                                   v-model="client.configuration.toggles.dynamic_flow"
                                   name="checkbox-client-dynamic_flow" sync />
                    Dynamic flow
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button v-model="client.configuration.toggles.swag_store_enabled"
                                   id="checkbox-client-swag_store_enabled"
                                   name="checkbox-client-swag_store_enabled" sync />
                    Swag store enabled
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-enrollment-d2c" label="D2C:">
                   <b-select v-model="client.configuration.d2c"
                             :disabled="editing"
                             :options="formatD2COptions()" />
                  </b-form-group>
                </b-col>
              </b-row>


              <b-form-group id="group-client-navigator_mode" label="Navigator behaviour:"
                            label-for="input-client-navigator_mode">
                <b-form-radio v-model="client.configuration.navigator_mode" name="app-navigator_mode-radios"
                              value="default">Default
                </b-form-radio>
                <b-form-radio v-model="client.configuration.navigator_mode" name="app-navigator_mode-radios"
                              value="dpt">Skip navigator and use DPT
                </b-form-radio>
                <b-form-radio v-model="client.configuration.navigator_mode" name="app-navigator_mode-radios"
                              value="bloom">Skip navigator and use Bloom
                </b-form-radio>
              </b-form-group>


            </b-col>
            <b-col cols="6">
              <b-form-group id="group-onboarding-flow" label="Onboarding Flow:" label-for="input-onboarding-flow">
                <b-select v-model="client.onboarding_flow_uuid" :options="this.onboardingFlowOptions" />
              </b-form-group>


              <b-form-group id="group-client-member_app_enrollment_experience"
                            label="Member-app enrollment experience:"
                            label-for="input-client-member_app_enrollment_experience">
                <b-form-radio v-model="client.configuration.member_app_enrollment_experience"
                              name="member_app_enrollment_experience" value="NATIVE">Native
                </b-form-radio>
                <b-form-radio v-model="client.configuration.member_app_enrollment_experience"
                              name="member_app_enrollment_experience" value="WEB">Web View
                </b-form-radio>
              </b-form-group>

              <b-form-group id="group-client-member_app_signup_experience" label="Member-app signup experience:"
                            label-for="input-client-member_app_signup_experience">
                <b-form-radio v-model="client.configuration.member_app_signup_experience"
                              name="member_app_signup_experience"
                              value="NATIVE">Native
                </b-form-radio>
                <b-form-radio v-model="client.configuration.member_app_signup_experience"
                              name="member_app_signup_experience"
                              value="WEB">Web View
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </b-card-text>
  </b-tab>
</template>
